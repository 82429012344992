<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="800">
      <v-toolbar dark>
        <v-btn icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('banners.' + action) }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="formValid" lazy-validation>
              <v-row>
                <v-col cols="4">
                  <v-text-field v-model="bannerDetailObj.nameZh" :rules="[
                    v => !!v || $t('common.required'),
                    v => v.length < 25 || 'on9'
                  ]" :label="$t('common.name_zh')" counter="25" />
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="bannerDetailObj.nameEng" :rules="[
                    v => !!v || $t('common.required'),
                    v => v.length < 500 || 'on9'
                  ]" counter="500" :label="$t('common.name_eng')" />
                </v-col>
                <v-col cols="4">
                  <v-select :items="bannersLocationOptions" v-model="bannerDetailObj.location"
                    :label="$t('common.location')" required :rules="[v => !!v || $t('common.required')]" />
                </v-col>
                <v-col cols="12" v-if="action == 'edit'">
                  <v-switch v-model="bannerDetailObj.published" :label="showStatus(bannerDetailObj.published)" />
                </v-col>
                <v-col cols="6">
                  <v-select :items="linkType" v-model="bannerDetailObj.type" :label="$t('banners.type')" required
                    :rules="[v => !!v || $t('common.required')]" />
                </v-col>
              </v-row>
              <v-row v-if="bannerDetailObj.type == 'webLink'">
                <v-col cols="12">
                  <v-text-field v-model="bannerDetailObj.link" :label="$t('common.link')"
                    :rules="[v => /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(v) || $t('common.valid_url')]"
                    hint="http://google.com" persistent-hint />
                </v-col>
              </v-row>
              <v-row v-if="bannerDetailObj.type == 'deepLink'">
                <v-col cols="6">
                  <v-select :items="bannersTypeOptions" v-model="selectedType" :label="$t('banners.type')" required
                    :rules="[v => !!v || $t('common.required')]" />
                </v-col>
                <v-col cols="6">
                  <v-autocomplete :items="typeOptionsList" v-model="selectedID" :label="$t('common.location')" text
                    item-text="name" item-value="_id" required :rules="[v => !!v || $t('common.required')]"
                    :disabled="selectedType == ''">
                    <template slot="item" slot-scope="data">
                      <!-- HTML that describe how select should render items when the select is open -->
                      <span v-if="['service', 'package'].includes(selectedType)"
                        style="font-size: 14px; margin-right: 5px;">({{ data.item.merchantName }}) </span> {{
                          data.item.name }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>{{ $t('banners.image') }}</v-card-title>
                    <v-card-text>
                      <v-img
                        v-if="bannerDetailObj.image && bannerDetailObj.image.large && bannerDetailObj.image.large.path"
                        class="mx-auto" :src="bannerDetailObj.image.large.path" max-width="500" />
                      <file-pond v-else name="upload" ref="pond" :label-idle="$t('img_label')"
                        accepted-file-types="image/jpeg, image/png" :files="uploadImageFile" allow-multiple="false"
                        max-file-size="5MB" v-on:removefile="bannerDetailObj.image = ''" />
                    </v-card-text>
                    <v-card-actions
                      v-if="bannerDetailObj.image && bannerDetailObj.image.large && bannerDetailObj.image.large.path">
                      <v-spacer />
                      <v-btn @click="bannerDetailObj.image = ''" text>
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" v-if="action == 'edit'">
                  <v-text-field :value="$moment(bannerDetailObj.createdAt).format('YYYY/MM/DD HH:mm')" disabled
                    :label="$t('common.created_at')" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" @click="save(action)">{{ $t('common.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
  components: {
    FilePond
  },
  props: ['packageList', 'merchantsList', 'serviceList'],
  mounted() {
    let self = this
    this.setOptions({
      server: {
        process: async function (fieldName, files, metadata, load, error, progress, abort) {
          try {
            let formData = new FormData()
            formData.append('file', files)

            let result = await api.post('/image', formData, {
              headers: { 'Content-Type': 'multipart/form-data' }
            })

            if (result) {
              self.bannerDetailObj.image = result.data._id
            }
            load(true)
          } catch (err) {
            console.log(err.message);
            abort(true)
          }
        }
      },
      imageResizeMode: 'contain',
    })
  },
  computed: {
    ...mapState(['lang']),
    bannersLocationOptions() {
      let result = this.locationArr

      result.map(item => {
        item.text = this.$t(item.i18n)
      })

      return result
    },
    bannersTypeOptions() {
      let result = this.bannerTypeArr

      result.map(item => {
        item.text = this.$t(item.i18n)
      })

      return result
    },
    typeOptionsList() {
      let result = []

      if (this.bannerDetailObj.type == 'deepLink' && this.selectedType != '') {
        switch (this.selectedType) {
          case 'service':
            result = this.serviceList
            break;
          case 'merchant':
            result = this.merchantsList
            break;
          case 'package':
            result = this.packageList
            break;
        }
      }

      return result
    }
  },
  methods: {
    ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
    async open(action, item) {
      if (action == 'edit') {
        let result = false

        try {
          result = await api.get('/banners/' + item._id)
        } catch (error) {
          this.PUSH_GLOBAL_SNACK_MESSAGES({
            message: error.response.data.message,
            icon: 'mdi-close',
          })
        }

        if (result) {
          this.bannerDetailObj = result.data
          if (this.bannerDetailObj.type == 'deepLink') {
            let findedType = this.bannerDetailObj.link.match(/^(.*?)(?=\?|$)/gm);
            let findedID = this.bannerDetailObj.link.match(/=\s*(.*)$/);
            this.selectedID = findedID[1]
            this.selectedType = findedType[0]
          }
        }
      }

      this.action = action
      this.dialog = true
    },
    async save() {
      let validated = this.$refs.form.validate()

      if (validated) {
        let result = false

        if (this.bannerDetailObj.image == '') {
          this.PUSH_GLOBAL_SNACK_MESSAGES({
            message: this.$t('global_alert.warning_upload_img'),
            icon: 'mdi-close',
          })

          return false
        }

        this.loading = true
        if (this.bannerDetailObj.type == 'deepLink') this.bannerDetailObj.link = this.selectedType + '?id=' + this.selectedID

        try {
          if (this.action == 'create') {
            result = await api.post('/banners', this.bannerDetailObj)
          } else {
            result = await api.patch('/banners', this.bannerDetailObj)
          }

          if (result) {
            let targetI18n = (this.action === 'edit') ? 'global_alert.success_edit' : 'global_alert.success_create'
            this.resetForm()
            this.dialog = false
            this.PUSH_GLOBAL_SNACK_MESSAGES({
              message: this.$t(targetI18n),
              icon: 'mdi-check',
            })
          }
        } catch (error) {
          this.PUSH_GLOBAL_SNACK_MESSAGES({
            message: error.response.data.message,
            icon: 'mdi-close',
          })
        }

        this.loading = false
      }
    },
    showStatus(status) {
      return status ? this.$t('common.enabled') : this.$t('common.disabled')
    },
    close() {
      this.resetForm()
      this.dialog = false
    },
    resetForm() {
      this.$emit('refresh')
      this.uploadImageFile = []
      this.selectedType = ''
      this.selectedID = ''
      this.bannerDetailObj = {
        nameZh: '',
        nameEng: '',
        type: '',
        image: [],
        link: '',
        location: ''
      }
    },
  },
  data: () => ({
    setOptions,
    dialog: false,
    uploadImageFile: [],
    linkType: ['webLink', 'deepLink'],
    locationArr: [{ i18n: 'common.home', value: 'home' }, { i18n: 'common.member', value: 'member' }],
    bannerTypeArr: [{ i18n: 'page_name.service', value: 'service' },
    { i18n: 'common.merchant', value: 'merchant' }, { i18n: 'common.package', value: 'package' }],
    bannerDetailObj: {
      nameZh: '',
      nameEng: '',
      type: '',
      image: [],
      link: '',
      location: ''
    },
    selectedType: '',
    selectedID: '',
    action: 'edit',
    formValid: true,
  })
}
</script>
